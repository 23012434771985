<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> Filtros </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4">
                <v-select :loading="loadingCities" v-model="filters.CityId" :items="cities" label="Cidade:"> </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="filters.blocked"
                  :items="[
                    { text: 'Ambos', value: null },
                    { text: 'Sim', value: 1 },
                    { text: 'Não', value: 0 },
                  ]"
                  label="Bloqueados"
                ></v-select
              ></v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="filters.createdFrom"
                  :items="[
                    { text: 'Ambos', value: null },
                    { text: 'Sim', value: 'internally' },
                    { text: 'Não', value: 'externally' },
                  ]"
                  label="Grupo especial"
                ></v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-radio-group v-model="filters.searchFor" label="Buscar por" mandatory row>
                  <v-radio label="Nome" :value="1"></v-radio>
                  <v-radio label="Empresa" :value="2"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field v-model="filters.search" clearable placeholder="Busca por nome ou empresa"></v-text-field>
              </v-col>
            </v-row>
            <div class="text-right">
              <v-btn @click="clearFilters()" class="mr-2"> Limpar filtros</v-btn>
              <v-btn color="primary" @click="loadAdvertisersData()"> Aplicar filtros</v-btn>
            </div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="mt-3">
      <v-toolbar flat>
        <v-card-title>Administrar clientes</v-card-title>

        <v-spacer></v-spacer>
        <v-btn small rounded color="success" @click="openInsertScreen"> Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <v-data-table :headers="headers" :loading="loading" :items="items" item-key="id" hide-default-footer disable-pagination>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="blue darken-2" icon title="Editar cadastro" @click="openUpdateScreen(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              :disabled="!item.Advertiser"
              icon
              :color="item.blocked ? 'green' : 'red'"
              :title="item.blocked ? 'Desbloquear' : 'Bloquear'"
              @click="toggleUserBlock(item.Advertiser.UserId, !item.blocked)"
            >
              <v-icon class="pr-1">
                {{ item.blocked ? "mdi-lock-open" : "mdi-lock" }}
              </v-icon>
            </v-btn>

            <v-btn icon color="grey darken-3" title="Excluir este cliente" @click="deleteAdvertiser(item.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination v-model="page" :length="numberOfPages" @input="loadAdvertisersData" :total-visible="15"></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data() {
    return {
      resultsPerPage: 15,
      totalRows: 0,
      page: 1,
      haveUnpostedAds: false,
      loading: false,
      loadingCities: false,
      headers: [
        {
          text: "Empresa",
          value: "companyName",
          sortable: true,
        },
        {
          text: "Proprietário",
          value: "userName",
          sortable: true,
        },
        { text: "Cidade", value: "cityName", sortable: false },
        {
          text: "Criado em",
          value: "createdAt",
          sortable: true,
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      cities: [],
      filters: {
        search: null,
        CityId: null,
        blocked: null,
        createdFrom: null,
        searchFor: 2,
      },
    };
  },
  methods: {
    setAdvertisersData(data) {
      const copyOfData = { ...data };

      this.totalRows = copyOfData.count;

      this.items = copyOfData.rows.map((item) => {
        item.userName = item.Advertiser ? `${item.Advertiser.User.firstName} ${item.Advertiser.User.lastName}` : "-";

        item.cityName = item.City ? `${item.City.name} - ${item.City.uf}` : "";

        item.createdAt = this.$moment(item.createdAt)
          // .subtract("3", "hours")
          .format("DD/MM/YYYY - HH:mm");

        if (item.Advertiser) item.blocked = item.Advertiser.User.blocked ? true : false;
        else item.blocked = false;

        return item;
      });
    },
    async loadAdvertisersData(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const response = await this.$axios.get(`admin/customers`, {
          params: {
            search: this.filters.search,
            searchFor: this.filters.searchFor,
            cityId: this.filters.CityId,
            blocked: this.filters.blocked,
            createdFrom: this.filters.createdFrom,
            pagination: 1,
            resultsPerPage: this.resultsPerPage,
            page: currentPage,
          },
        });

        this.setAdvertisersData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    async toggleUserBlock(userId, isBlock) {
      try {
        const msg = isBlock ? "Bloquear cadastro deste cliente?" : "Desbloquear cadastro deste cliente?";

        if (
          !(await this.$root.$confirm("Atenção", msg, {
            color: "primary",
          }))
        )
          return;

        this.loading = true;

        await this.$axios.put(`/admin/customers/${userId}/block`, {
          blocked: isBlock,
        });

        this.toggleUserBlockInArray(userId);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    toggleUserBlockInArray(userId) {
      const index = this.items.findIndex((item) => item.Advertiser && item.Advertiser.UserId === userId);

      if (index !== -1) this.items[index].blocked = !this.items[index].blocked;
    },
    async deleteAdvertiser(companyId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente excluir o cliente selecionado? Essa ação deletará também os seus anúncios e é irreversível.",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/admin/customers/${companyId}`);

        this.deleteAdvertiserInArray(companyId);

        this.$toast.success("Dados deletados com sucesso");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    deleteAdvertiserInArray(companyId) {
      const index = this.items.findIndex((item) => item.id === companyId);

      if (index !== -1) this.items.splice(index, 1);
    },
    clearFilters() {
      this.filters = {
        search: null,
        CityId: null,
        blocked: null,
        createdFrom: null,
        searchFor: 1,
      };
      this.loadAdvertisersData();
    },
    openInsertScreen() {
      this.$router.push("/clientes/cadastrar");
    },
    openUpdateScreen(companyId) {
      this.$router.push(`/clientes/${companyId}/editar`);
    },
    async getCities() {
      try {
        this.loadingCities = true;

        const res = await this.$axios.get("/cities");

        this.setCitiesData(res.data);

        this.loadingCities = false;
      } catch (error) {
        this.loadingCities = false;
        this.handleErrors(error);
      }
    },
    setCitiesData(cities) {
      this.cities = cities.map((c) => {
        return {
          value: c.id,
          text: `${c.name} - ${c.uf}`,
        };
      });
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.loadAdvertisersData();
    this.getCities();
  },
};
</script>

<style></style>
