<template>
  <div>
    <v-card>
      <v-card-title>Planos de anúncio</v-card-title>

      <v-card-subtitle
        >Configure os planos que os anúnciantes podem
        selecionar</v-card-subtitle
      >

      <v-data-table
        :headers="headers"
        :items="plans"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              small
              color="success"
              @click="$router.push('/planos/cadastrar')"
              >Cadastrar +</v-btn
            >
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="mr-2"
            title="Editar plano"
            @click="editPlan(item.id)"
            small
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            title="Deletar plano"
            @click="deletePlan(item.id)"
            small
            icon
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  data: () => ({
    panel: null,
    loading: false,
    headers: [
      { text: "#ID", value: "id" },
      { text: "Nome", value: "name" },
      { text: "Ações", value: "actions" },
    ],
    plans: [],
    features: [],
  }),
  methods: {
    async getPlans() {
      try {
        this.loading = true;

        const res = await this.$axios.get("/plans");

        this.setPlansData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setPlansData(data) {
      this.plans = [...data];
    },
    async deletePlan(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar o plano com o id n. ${id} ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/plans/${id}`;

        await this.$axios.delete(url);

        this.deletePlanFromTable(id);

        this.$toast.success("Dados deletados com sucesso");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deletePlanFromTable(id) {
      const index = this.plans.findIndex((p) => p.id === id);

      this.plans.splice(index, 1);
    },
    editPlan(id) {
      this.$router.push(`/planos/${id}`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.getPlans();
  },
};
</script>

<style></style>
